<template lang='pug'>
div
  base-header(class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success")
  b-container(fluid class="mt--9" style='padding-left:0; padding-right:0')
    b-card(no-body)
      iframe(:src="dashboard_link" width='100%' v-resize="{checkOrigin: false, log:true}" frameborder='0')
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      dashboard_link: null
    };
  },
  async beforeMount() {
    let response = await this.axios.get('/partner/dashboard/')
    this.dashboard_link = response.data['dashboard_link']
  },
  mounted() {

  }
};
</script>
