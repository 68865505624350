<template lang='pug'>
div
  base-header(class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success")
  b-container(fluid class="mt--9")
    card
      b-row(align-v="center" slot="header")
        b-col(cols="8")
          h3(class="mb-0") Edit profile
      b-form(id='form')
        h6(class="heading-small text-muted mb-4") User information
        div(class="pl-lg-4")
          b-row
            b-col(lg="6")
              base-input(type="text" label="Username" placeholder="Username" v-model="$auth.user().username" readonly)
            b-col(lg="6")
              base-input(type="email" label="Email address" placeholder="" v-model="$auth.user().email" readonly)
          b-row
            b-col(lg="6")
              base-input(type="text" label="First Name" placeholder="First Name" v-model="$auth.user().first_name" readonly)
            b-col(lg="6")
              base-input(type="text" label="Last Name" placeholder="Last Name" v-model="$auth.user().last_name" readonly)
        hr(class="my-4")
        h6(class="heading-small text-muted mb-4") Organization
        div(class="pl-lg-4")
          b-row
            b-col(lg="6")
              base-input(type="text" label="IIN/BIN" placeholder="IIN/BIN" v-model="$auth.user().organization.identifier" readonly)
            b-col(lg="6")
              base-input(type="text" label="Name" placeholder="" v-model="$auth.user().organization.name")
          b-row
            b-col(lg="6")
              base-input(type="text" label="Device Token" placeholder="" v-model="$auth.user().organization.device_token" readonly)
            b-col(lg="6")
          b-row
            b-col(lg="6")
              base-input(type="text" label="Cash Serial" placeholder="" v-model="$auth.user().organization.cash_serial" readonly)
            b-col(lg="6")
              base-input(type="text" label="Cash Password" placeholder="" v-model="$auth.user().organization.cash_password" readonly)
          b-button(size='sm' variant='primary' @click='updateOrganization') Save
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    updateOrganization() {
      this.axios.put(`/partner/organization/${this.$auth.user().organization.id}/`, {name:this.$auth.user().organization.name})
    }
  }
}
</script>
<style>
</style>
